export const i18n = {
    internalServerError: {
        id: 'notification.internal-server-error',
        defaultMessage: 'There was an error processing the request. Please contact support.',
    },
    gatewayTimeoutError: {
        id: 'notification.gateway-timeout',
        defaultMessage:
            'Request submitted exceeded timeout. The operation will continue in the background. Please contact support.',
    },
    mapTokenFailed: {
        id: 'notification.map-token-failed',
        defaultMessage: 'An error occured while retrieving map token.',
    },
    serverUnavailable: {
        id: 'notification.serverUnavailable',
        defaultMessage: 'Server unavailable. Please contact support.',
    },
    imagoSync: {
        id: 'notification.imagoSync',
        defaultMessage: '{numImageries} imageries have been synchronized.',
    },
    imagoNoneSync: {
        id: 'notification.imagoNoneSync',
        defaultMessage: 'No imageries have been synchronized.',
    },
    unauthorized: {
        id: 'notification.unauthorized',
        defaultMessage: 'Unauthorized.',
    },
    resourceNotFound: {
        id: 'notification.resourceNotFound',
        defaultMessage: 'Resource not found.',
    },
    listItemCreated: {
        id: 'notification.listItemCreated',
        defaultMessage: '{name} list has been created.',
    },
    listEdited: {
        id: 'notification.listEdited',
        defaultMessage: '{name} list has been updated.',
    },
    tableItemCreated: {
        id: 'notification.tableItemCreated',
        defaultMessage: '{name} table has been created.',
    },
    tableViewItemCreated: {
        id: 'notification.tableviewItemCreated',
        defaultMessage: '{name} table view has been created.',
    },
    tableEdited: {
        id: 'notification.tableEdited',
        defaultMessage: '{name} table has been updated.',
    },
    headerItemCreated: {
        id: 'notification.headerItemCreated',
        defaultMessage: '{name} header has been created.',
    },
    dispatchHeaderItemCreated: {
        id: 'notification.dispatchHeaderItemCreated',
        defaultMessage: '{name} dispatch header has been created.',
    },
    headerEdited: {
        id: 'notification.headerEdited',
        defaultMessage: '{name} header has been updated.',
    },
    dispatchHeaderEdited: {
        id: 'notification.dispatchHeaderEdited',
        defaultMessage: '{name} dispatch header has been updated.',
    },
    headerFieldAdded: {
        id: 'notification.headerFieldAdded',
        defaultMessage: 'Field {name} has been added.',
    },
    headerFieldEdited: {
        id: 'notification.headerFieldEdited',
        defaultMessage: 'Field {name} has been updated.',
    },
    headerSampleFieldAdded: {
        id: 'notification.dispatchHeaderFieldAdded',
        defaultMessage: 'Dispatch field {name} has been added.',
    },
    headerSampleFieldEdited: {
        id: 'notification.dispatchHeaderFieldEdited',
        defaultMessage: 'Dispatch field {name} has been updated.',
    },
    importListSucceeded: {
        id: 'notification.headerFieldEdited',
        defaultMessage: '{text} to {list}',
    },
    singleBulkImportSuccess: {
        id: 'notification.singleBlukImportSuccess',
        defaultMessage: 'Your list was imported successfully.',
    },
    multiBulkImportSuccess: {
        id: 'notification.multiBulkImportSuccess',
        defaultMessage: 'All {count} files were imported successfully.',
    },
    editTableFailed: {
        id: 'notification.editTableFailed',
        defaultMessage: 'Validation rules of tables added should match with the table view.',
    },
    importTableSuccess: {
        id: 'notification.importTableSuccess',
        defaultMessage: 'Your file has been imported successfully.',
    },
    importTablesSuccess: {
        id: 'notification.importTablesSuccess',
        defaultMessage: 'Your files have been imported successfully.',
    },
    activityItemCreated: {
        id: 'notification.activityItemCreated',
        defaultMessage: '{name} activity has been created.',
    },
    activityEdited: {
        id: 'notification.activityEdited',
        defaultMessage: '{name} activity has been updated.',
    },
    projectCreated: {
        id: 'notification.projectCreated',
        defaultMessage: '{name} project has been created.',
    },
    projectUpdated: {
        id: 'notification.projectUpdated',
        defaultMessage: '{name} project has been updated.',
    },
    projectThumbnailUpdated: {
        id: 'notification.projectThumbnailUpdated',
        defaultMessage: '{name} thumbnail has been updated.',
    },
    projectThumbnailDeleted: {
        id: 'notification.projectThumbnailDeleted',
        defaultMessage: '{name} thumbnail has been deleted.',
    },
    sampleWorkflowCreated: {
        id: 'notification.sampleWorkflowCreated',
        defaultMessage: '{name} analysis workflow has been created.',
    },
    sampleWorkflowEdited: {
        id: 'notification.sampleWorkflowEdited',
        defaultMessage: '{name} analysis workflow has been updated.',
    },
    sampleWorkflowDeleted: {
        id: 'notification.sampleWorkflowdeleted',
        defaultMessage: '{name} sample workflow has been deleted.',
    },
    sampleCodesImported: {
        id: 'notification.sampleCodesImported',
        defaultMessage: 'Sample codes have been imported successfully.',
    },
    coordinatesImported: {
        id: 'notification.coordinatesImported',
        defaultMessage: 'Coordinate reference systems operations updated successfully.',
    },
    coordinateSaveWarning: {
        id: 'notification.coordinateSaveWarning',
        defaultMessage:
            'Please do not navigate away from this page to ensure the process completes successfully. \nThis may take a few moments.',
    },
    longProcessWarning: {
        id: 'notification.longProcessWarning',
        defaultMessage: 'Please be aware this process may take longer than expected.',
    },
    gridCreated: {
        id: 'notification.sampleWorkflowdeleted',
        defaultMessage: '{name} grid has been created.',
    },
    gridUpdated: {
        id: 'notification.gridUpdated',
        defaultMessage: '{name} grid has been updated.',
    },
    activityGroupCreated: {
        id: 'notification.activityGroupCreated',
        defaultMessage: '{name} activity group has been created.',
    },
    activityGroupUpdated: {
        id: 'notification.activityGroupUpdated',
        defaultMessage: '{name} activity group has been updated.',
    },
    categoryCreated: {
        id: 'notification.categoryCreated',
        defaultMessage: '{name} category has been created.',
    },
    categoryUpdated: {
        id: 'notification.categoryUpdated',
        defaultMessage: '{name} category has been updated.',
    },
    sharedWithYou: {
        id: 'notification.sharedWithYou',
        defaultMessage: '{user} shared a private post with you.',
    },
    newReply: {
        id: 'notification.newReply',
        defaultMessage: '{user} replied to {creator} post.',
    },
    mentionedMultiple: {
        id: 'notification.mentionedMultiple',
        defaultMessage: '{user} mentioned you and {mentions} others in their post.',
    },
    mentionedOne: {
        id: 'notification.mentionedOne',
        defaultMessage: '{user} mentioned you and 1 other in their post.',
    },
    mentionedYou: {
        id: 'notification.mentionedYou',
        defaultMessage: '{user} mentioned you in their post.',
    },
    validationRuleCreated: {
        id: 'notification.validationRuleCreated',
        defaultMessage: '{name} validation rule has been created.',
    },
    validationRuleDeleted: {
        id: 'notification.validationRuleDeleted',
        defaultMessage: '{name} validation rule has been deleted.',
    },
    requiredField: {
        id: 'notification.requiredField',
        defaultMessage: '{name} is a required field.',
    },
    exportTemplateCreated: {
        id: 'notification.exportTemplateCreated',
        defaultMessage: '{name} export template has been created.',
    },
    exportTemplateUpdated: {
        id: 'notification.exportTemplateUpdated',
        defaultMessage: '{name} export template has been updated.',
    },
    validationRuleEdited: {
        id: 'notification.validationRuleEdited',
        defaultMessage: '{name} validation rule has been updated.',
    },
    jobCreationSuccess: {
        id: 'notification.jobCreationSuccess',
        defaultMessage:
            'Your job has been successfully submitted. Please visit the <a1>Jobs</a1> page.',
    },
};
